// Regex patterns
const LINK_PARSE_REG_EXP = /^(\/\/|http:\/\/|https:\/\/|#)?(.*)/;
const VALIDATE_LINK_REG_EXP = /^(?:\w+:)?\/\/([^\s.]+\.\S{2}|localhost[\d:?]*)\S*$/;
const VALIDATE_EMAIL_REG_EXP = /\S+@\S+\.\S+/;
// eslint-disable-next-line max-len, vue/max-len
const VALID_DOMAIN_REG_EXP = /^(((([\dA-Za-z])|([\dA-Za-z][\dA-Za-z-]{0,86}[\dA-Za-z]))\.(([\dA-Za-z])|([\dA-Za-z][\dA-Za-z-]{0,73}[\dA-Za-z]))\.(([\dA-Za-z]{2,162}\.[\dA-Za-z]{2,12})|([\dA-Za-z]{2,25})))|((([\dA-Za-z])|([\dA-Za-z][\dA-Za-z-]{0,162}[\dA-Za-z]))\.(([\dA-Za-z]{2,12}\.[\dA-Za-z]{2,12})|([\dA-Za-z]{2,25}))))$/;

export const isValidDomain = (domain) => domain?.length >= 4 && VALID_DOMAIN_REG_EXP.test(domain);

export const stripUrl = (url = '') => {
	let decodedUrl = decodeURI(url).trim();
	const replaceValues = [
		/\s/g,
		/^tel:/,
		/^mailto:/,
	];

	replaceValues.forEach((value) => {
		decodedUrl = decodedUrl.replace(value, '');
	});

	return decodedUrl;
};

// Validate email
export const getValidEmail = (url = '') => {
	const link = stripUrl(url);

	if (VALIDATE_EMAIL_REG_EXP.test(link)) {
		return {
			url: `mailto:${link}`,
			isUrlValid: true,
		};
	}

	return {
		url,
		isUrlValid: false,
	};
};

export const isUrlValid = (url = '') => VALIDATE_LINK_REG_EXP.test(url);

// Validated page URL
export const getValidUrl = (url = '', generateUrlWithHttp = false) => {
	const link = encodeURI(url.trim());
	const linkRegexMatch = link.match(LINK_PARSE_REG_EXP);
	const linkPrefix = linkRegexMatch[1];
	const generatedUrl = generateUrlWithHttp ? `http://${linkRegexMatch[2]}` : `//${linkRegexMatch[2]}`;

	if ([
		'http://',
		'https://',
		'//',
	].includes(linkPrefix)) {
		return {
			url: link,
			isUrlValid: isUrlValid(link),
		};
	}

	if (linkPrefix === '#') {
		return {
			url: link,
			isUrlValid: true,
		};
	}

	if (getValidEmail(link).isUrlValid) {
		return {
			url,
			isUrlValid: false,
		};
	}

	if (isUrlValid(generatedUrl)) {
		return {
			url: generatedUrl,
			isUrlValid: true,
		};
	}

	return {
		url,
		isUrlValid: false,
	};
};

// Get valid page path. Disallow multi-level paths. Allow only `/some-path`pattern
export const getValidPagePath = (url = '') => {
	const formattedUrl = `/${url
		.replace(/[^\d a-z-]/gi, '') // trim all not allowed chars
		.replace(/\s+/g, '-') // replace every space with '-'
		.toLowerCase()
	}`;

	return {
		url: formattedUrl,
		isUrlValid: true,
	};
};

// Validate phone number
export const getValidPhone = (url = '') => {
	const link = stripUrl(url);

	if (link && !/[A-Za-z]/.test(link)) {
		return {
			url: `tel:${link}`,
			isUrlValid: true,
		};
	}

	return {
		url,
		isUrlValid: false,
	};
};

// Convert google map address to URL
export const addressToGoogleMapUrl = (address = '') => (
	address
		? `https://maps.google.com/maps?q=${encodeURI(address)}&t=m&z=13&ie=UTF8&output=embed`
		: '');

// Convert google map URL to address
export const googleMapUrlToAddress = (url = '') => (url ? decodeURI(url.split('?q=')[1].split('&t=')[0]) : '');

// Creates page URL
export const getPageUrl = (url = '') => (url ? `/${url
	.replace(/\s+/g, '-')
	.replace(/(^\/|-$)/g, '')
	.toLowerCase()
	.replace(/[^\w\s-/]/gi, '')}` : '');

export const getNonRelativeUrl = (url = '') => {
	if (url) {
		return `${url.startsWith('http') ? '' : '//'}${url}`;
	}

	return url;
};
