<template>
	<ZyroFieldText
		v-qa="'wizard-importer-input'"
		input-theme="secondary"
		class="input"
		autofocus
		focus-on-mount
		:value="localValue"
		placeholder="example.com"
		:error="error"
		@input="onInputChange"
		@keypress.enter="$emit('enter')"
	/>
</template>

<script>
import {
	ref,
	watchEffect,
} from '@vue/composition-api';

import i18n from '@/i18n/setup';
import { getValidUrl } from '@/utils/urlValidators';

const ERROR_MESAGE = i18n.t('validate.url');

export default {
	props: {
		value: {
			type: String,
			required: true,
		},
		hasPressedContinue: {
			type: Boolean,
			required: true,
		},
	},
	setup(props, { emit }) {
		const error = ref(null);
		const localValue = ref('');

		const setValidationError = () => {
			if (!getValidUrl(localValue.value, true).isUrlValid && props.hasPressedContinue) {
				error.value = ERROR_MESAGE;
				emit('url-valid', false);
			}
		};

		watchEffect(() => {
			if (props.hasPressedContinue) {
				setValidationError();
			}
		});

		const onInputChange = (event) => {
			const { value } = event.target;
			const siteUrl = getValidUrl(value, true);

			localValue.value = value;
			error.value = null;

			emit('url-valid', siteUrl.isUrlValid);

			setValidationError();

			emit('input', siteUrl.url);
		};

		return {
			getValidUrl,
			localValue,
			error,
			onInputChange,
		};
	},
};
</script>

<style lang="scss" scoped>
.input {
	width: 100%;
	max-width: 446px;
	padding-top: 72px;
	margin-bottom: 8px;
}
</style>
